import * as Yup from "yup";
import Photo from "../../../../components/Photo";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect, useRef } from "react";
import productService from "../services/productService";
import AlertMessage from "../../../../components/alert/alert";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  TextField,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
} from "@mui/material";

// import clientService from '../services/clientService';

interface ModalProps {
  title: string;
  open: boolean;
  handler: () => void;
  confirmButtonText: string;
  modalData: any;
}

export default function ProductModal(props: ModalProps) {
  const isMounted = useRef(true);
  const [showError, setError] = useState(false);
  const [imageFire, setImageFire] = useState("");
  const [finalData, setFinalData] = useState({});
  const [showSuccess, setSuccess] = useState(false);
  const [fileFire, setFileFire] = useState<any[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showSuccessUpdate, setSuccessUpdate] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name_Lens: Yup.string().required("Nome do produto é obrigatório"),
  });
  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      name_Lens: props.modalData ? props.modalData.pictureFilename_Lens : "",
      sigaCode_Lens: props.modalData ? props.modalData.sigaCode_Lens : "",
      pictureFilename_Lens: props.modalData
        ? props.modalData.pictureFilename_Lens
        : "",
      pictureFilepath_Lens: props.modalData
        ? props.modalData.pictureFilepath_Lens
        : "",
    },
    onSubmit: () => {},
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
    if (showSuccessUpdate) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess, showSuccessUpdate]);

  useEffect(() => {
    if (formik.isValid && isMounted.current) {
      setFinalData({ ...values, file: fileFire[0] });
    }
  }, [formik.isValid, values, fileFire]);

  const saveLens = () => {
    setSubmitting(true);
    productService
      .saveLens(finalData)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        props.handler();
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setError(true);
      });
  };

  const updateLens = () => {
    setSubmitting(true);
    productService
      .updateLeans(finalData, props.modalData.id_Lens)
      .then(() => {
        setSubmitting(false);
        setSuccessUpdate(true);
        props.handler();
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setError(true);
      });
  };

  useEffect(() => {
    setImageFire(values.pictureFilepath_Lens);
  }, [values.pictureFilepath_Lens]);

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          formik.resetForm();
          setImageFire("");
          setFileFire([]);
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ minWidth: "30vw" }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent>
                <Typography
                  variant="h4"
                  marginBottom={4}
                  color="#004A78"
                  gutterBottom
                >
                  Novo Produto
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="h6" component="h2">
                    Foto/Logo
                  </Typography>
                  <Photo
                    logo
                    imageFire={imageFire}
                    setImageFire={(value: any) => setImageFire(value)}
                    setFileFire={(value: any[]) => setFileFire(value)}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Nome do Produto
                  </Typography>
                  <TextField
                    type="text"
                    fullWidth={true}
                    color="secondary"
                    variant="outlined"
                    placeholder="Digite aqui..."
                    {...getFieldProps("name_Lens")}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={Boolean(touched.name_Lens && errors.name_Lens)}
                    helperText={touched.name_Lens && errors.name_Lens}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Código do produto
                  </Typography>
                  <TextField
                    type="text"
                    fullWidth={true}
                    color="secondary"
                    variant="outlined"
                    placeholder="Código do produto"
                    {...getFieldProps("sigaCode_Lens")}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={Boolean(
                      touched.sigaCode_Lens && errors.sigaCode_Lens
                    )}
                    helperText={touched.sigaCode_Lens && errors.sigaCode_Lens}
                  />
                </Stack>
              </CardContent>
              <CardActions>
                {props.modalData === "" ? (
                  <LoadingButton
                    fullWidth
                    onClick={saveLens}
                    loading={isSubmitting}
                    disabled={!formik.isValid}
                    variant="contained"
                    sx={{
                      m: 1,
                      height: "48px",
                      borderRadius: "30px",
                      backgroundColor: "#CDCDCD",
                      color: "#004A78",
                      "&:hover": {
                        bgcolor: "#004A78",
                        color: "#FEFEFE",
                      },
                    }}
                    autoFocus
                  >
                    {props.confirmButtonText}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    fullWidth
                    onClick={updateLens}
                    loading={isSubmitting}
                    disabled={!formik.isValid}
                    variant="contained"
                    sx={{
                      m: 1,
                      height: "48px",
                      borderRadius: "30px",
                      backgroundColor: "#CDCDCD",
                      color: "#004A78",
                      "&:hover": {
                        bgcolor: "#004A78",
                        color: "#FEFEFE",
                      },
                    }}
                    autoFocus
                  >
                    Atualizar
                  </LoadingButton>
                )}
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      {showSuccess && (
        <AlertMessage open={true} message="Produto cadastrado com sucesso!" />
      )}
      {showSuccessUpdate && (
        <AlertMessage open={true} message="Produto atualizado com sucesso!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar cadastro" />
      )}
    </>
  );
}
