import http from "../../../../services/http-common";
import firebase from "firebase/app";
import "firebase/storage";

class ProductService {
  // eslint-disable-next-line class-methods-use-this
  async saveLens(data: any) {
    const storage = firebase.storage();

    const filePath = `produtos/${data.name_Lens}`;
    const storageRef = await storage.ref(filePath);
    await storageRef.put(data.file);

    const urlDownload = await storageRef.getDownloadURL();
    try {
      const lens = {
        name_Lens: data.name_Lens?.toUpperCase(),
        pictureFilepath_Lens: urlDownload,
        pictureFilename_Lens: `${data.name_Lens}`,
        sigaCode_Lens: data.sigaCode_Lens,
      };

      return http.post("/lens", lens);
    } catch (error) {
      console.log(error);
    }
  }

  async updateLeans(data: any, id_lens: any) {
    try {
      let urlDownload = "";
      if (data.file) {
        const storage = firebase.storage();

        const filePath = `produtos/${data.name_Lens}`;
        const storageRef = await storage.ref(filePath);
        await storageRef.put(data.file);

        urlDownload = await storageRef.getDownloadURL();
      } else {
        urlDownload = data.pictureFilepath_Lens;
      }
      const lens = {
        name_Lens: data.name_Lens?.toUpperCase(),
        pictureFilepath_Lens: urlDownload,
        pictureFilename_Lens: `${data.name_Lens}`,
        sigaCode_Lens: data.sigaCode_Lens,
      };
      return http.put(`/lens/update/${id_lens}`, lens);
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getById(id: string) {
    return http.get(`lens/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(): any {
    return http.get("lens/find");
  }

  // eslint-disable-next-line class-methods-use-this
  deleteLens(id: string) {
    return http.delete(`/lens/delete/${id}`);
  }
}

export default new ProductService();
